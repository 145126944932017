import styled from '@emotion/styled'
import { borderRadius, colorTokens, spacing } from '@orus.eu/pharaoh'

export const CustomIconButton = styled.div<{ active: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: ${spacing['10']};
  padding: ${spacing['30']};
  border-radius: ${borderRadius['20']};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ active }) => (active ? colorTokens['color-text-base-main'] : colorTokens['color-text-base-disable'])};

  &:hover {
    color: ${({ disabled }) =>
      disabled ? colorTokens['color-text-base-disable'] : colorTokens['color-text-base-main']};
    background-color: ${({ disabled }) => (disabled ? 'transparent' : colorTokens['color-bg-base-hover'])};
  }
`
