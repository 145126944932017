import { css } from '@emotion/react'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { getCompanyIdNumberValue, parseSirenValue, type CompanyIdNumber } from '@orus.eu/company-id-number'
import type { CompanySectionState } from '@orus.eu/dimensions'
import {
  DatepickerFormField,
  FormRow,
  spacing,
  Text,
  TextFieldFormField,
  ValidatedTextFieldFormField,
  type ValidatedTypeMapper,
} from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'
import { memo, useState } from 'react'
import { openLinkInNewWindow } from '../../../../../lib/window-util'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DocumentGroupStatus } from '../DetailedSheetPage'
import CommonSection from './common/CommonSection'
import DocumentButton from './common/DocumentButton'
import DocumentsList from './common/DocumentsList'

type CompanySectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: CompanySectionState
  stateBefore: CompanySectionState
  setChanges: (changes: CompanySectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (id: GroupCategory) => void
}

export const CompanySection = memo(function CompanySection(props: CompanySectionProps) {
  const { state, setChanges, onIdFileGroupStatusClick, idsFileGroupStatus } = props

  const { siretData, companyName, companyCreationDate, companyIdNumber, detailedSheetCompanySynthesis } = state

  const [companyNameValue, setCompanyNameValue] = useState(companyName ?? '')
  const [companyCreationDateValue, setCompanyCreationDateValue] = useState(companyCreationDate)
  const [companyIdNumberValue, setCompanyIdNumberValue] = useState(companyIdNumber)

  const hasValidSiren =
    companyIdNumberValue &&
    (companyIdNumberValue.type === 'provided' ||
      companyIdNumberValue.type === 'inferred' ||
      companyIdNumberValue.type === 'siren')

  const hasProvidedSiret = siretData?.type === 'provided'

  const handleClick = (category: GroupCategory, status: GroupStatus) => {
    if (status !== 'empty') {
      onIdFileGroupStatusClick(category)
    }
  }

  const leftContent = (
    <>
      <FormRow>
        <TextFieldFormField
          value={companyNameValue}
          label="Raison sociale"
          size="small"
          placeholder="CRM France"
          onBlur={() => setChanges({ companyName: companyNameValue })}
          onChange={(value) => setCompanyNameValue(value.target.value)}
        />
      </FormRow>
      <FormRow>
        <ValidatedTextFieldFormField<CompanyIdNumber | undefined>
          size={'small'}
          label={'SIREN'}
          value={companyIdNumberValue}
          placeholder={'552 178 639 00132'}
          onBlur={() => setChanges({ companyIdNumber: companyIdNumberValue })}
          onChange={(value) => setCompanyIdNumberValue(value)}
          mapper={sirenMapper}
          infoTooltip={''}
          required={true}
        />
        <DatepickerFormField
          required
          label="Date de création ou reprise"
          value={companyCreationDateValue}
          size="small"
          fullWidth={true}
          infoTooltip="Date d'immatriculation qui figure sur votre extrait Kbis"
          onChange={(value) => {
            setCompanyCreationDateValue(value)
            setChanges({ companyCreationDate: value })
          }}
        />
      </FormRow>
    </>
  )

  const rightContent = (
    <>
      <DocumentsList idsFileGroupStatus={idsFileGroupStatus} onIdFileGroupStatusClick={handleClick} />
      <Text
        css={css`
          margin-top: ${spacing[50]};
        `}
        variant="body2Medium"
      >
        Liens
      </Text>
      <DocumentButton
        title={'CreditSafe'}
        rightIcon="arrow-up-right-regular"
        disabled={!hasProvidedSiret}
        onClick={() => {
          if (hasProvidedSiret) {
            const siret = siretData.siret
            openLinkInNewWindow(`https://app.creditsafe.com/companies/FR-X-${siret}`)
          }
        }}
      />
      <DocumentButton
        title={'Pappers'}
        rightIcon="arrow-up-right-regular"
        disabled={!hasValidSiren}
        onClick={() => {
          if (hasValidSiren) {
            const siren = parseSirenValue(companyIdNumberValue!.siren)
            const unifiedCompanyName = getUnifiedCompanyName(companyNameValue)
            openLinkInNewWindow(`https://www.pappers.fr/entreprise/${unifiedCompanyName}-${siren}`)
          }
        }}
      />
    </>
  )

  return (
    <CommonSection
      value={detailedSheetCompanySynthesis}
      title="Entreprise"
      leftContent={leftContent}
      rightContent={rightContent}
      defaultTable="company"
      onChange={(value) => {
        setChanges({ detailedSheetCompanySynthesis: value })
      }}
    />
  )
})

const sirenMapper: ValidatedTypeMapper<CompanyIdNumber | undefined> = {
  inputType: 'text',
  format: formatValueForTextInput,
  formatPlaceholder: formatValueForTextInput,
  parse: (text) => {
    text = text.trim()
    if (text === '') return success(undefined)
    const siren = parseSirenValue(text)
    return siren ? success({ type: 'provided', siren }) : failure('Entrer les 9 chiffres de votre SIREN')
  },
}

function formatValueForTextInput(value: CompanyIdNumber | undefined): string {
  return getCompanyIdNumberValue(value) ?? ''
}

function getUnifiedCompanyName(companyName: string): string {
  return companyName.toLowerCase().replace(/\s+/g, '-')
}
