import { colorTokens, shadow, spacing } from '@orus.eu/pharaoh'
import { memo, useRef } from 'react'
import { Editor, Transforms, type BaseEditor } from 'slate'

import usePopup from '../hooks/usePopup.js'

import styled from '@emotion/styled'
import type { ReactEditor } from 'slate-react'
import { CustomIconButton } from '../components/button/CustomIconButton.js'
import { Emoji } from '../components/icons/index.js'
import { emojis } from './emojis.js'

const EmojiPicker = memo(function EmojiPicker({ editor }: { format: 'emoji'; editor: BaseEditor & ReactEditor }) {
  const emojiPickerRef = useRef(null)
  const [showOptions, setShowOptions] = usePopup(emojiPickerRef)

  const toggleOption = () => {
    setShowOptions((prev) => !prev)
  }
  const insertEmoji = (emoji: string) => {
    Transforms.insertText(editor, ' ')
    Editor.addMark(editor, 'fontSize', 24)
    Transforms.insertText(editor, emoji)
    Editor.addMark(editor, 'fontSize', 14)
    Transforms.insertText(editor, ' ')

    setShowOptions(false)
  }

  return (
    <EmojiPickerContainer ref={emojiPickerRef}>
      <CustomIconButton active={true} onClick={toggleOption}>
        <Emoji />
      </CustomIconButton>
      {showOptions && (
        <EmojiPickerDropdownContainer>
          {emojis.map((emoji, index) => {
            return (
              <EmojiUnitContainer key={index} onClick={() => insertEmoji(emoji)}>
                {emoji}
              </EmojiUnitContainer>
            )
          })}
        </EmojiPickerDropdownContainer>
      )}
    </EmojiPickerContainer>
  )
})

export default EmojiPicker

const EmojiPickerContainer = styled.div`
  position: relative;
`

const EmojiPickerDropdownContainer = styled.div`
  position: absolute;
  background: ${colorTokens['color-bg-base-secondary']};
  left: 0;
  padding: ${spacing['30']};
  border: ${`${spacing['5']} solid ${colorTokens['color-stroke-base']}`};
  border-radius: ${spacing['30']};
  box-shadow: ${shadow.bottom[20]};
  height: fit-content;
  z-index: 1;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  gap: ${spacing['20']};
`

const EmojiUnitContainer = styled.div`
  cursor: pointer;
  border-radius: ${spacing['20']};
  font-size: 24px;
`
