import { memo } from 'react'
import type { RenderElementProps } from 'slate-react'

type TableFooterProps = RenderElementProps

const TableFooter = memo(function TableFooter({ attributes, children }: TableFooterProps) {
  return (
    <tfoot className="" {...attributes}>
      {children}
    </tfoot>
  )
})

export default TableFooter
