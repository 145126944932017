import { memo, type CSSProperties } from 'react'
import type { RenderLeafProps } from 'slate-react'

type CustomLeaf = {
  color?: string
  backgroundColor?: string
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  code?: boolean
  fontSize?: string
  // [key: string]: any // For additional custom properties
}

type LeafProps = RenderLeafProps & {
  leaf: CustomLeaf
}

const CustomizableLeaf = memo(function CustomizableLeaf({ attributes, children, leaf }: LeafProps) {
  const style: CSSProperties = {
    color: leaf.color,
    backgroundColor: leaf.backgroundColor,
    fontSize: leaf.fontSize ? `${leaf.fontSize}px` : undefined,
    fontWeight: leaf.bold ? 'bold' : undefined,
    fontStyle: leaf.italic ? 'italic' : undefined,
    textDecoration: [leaf.underline ? 'underline' : '', leaf.strikethrough ? 'line-through' : '']
      .filter(Boolean)
      .join(' '),
  }

  return (
    <span style={style} {...attributes}>
      {children}
    </span>
  )
})

export default CustomizableLeaf
