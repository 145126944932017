import { type Descendant } from 'slate'

const EMPTY_PARAGRAPH: Descendant = {
  type: 'paragraph',
  children: [{ text: '' }],
}

const EMPTY_TABLE_CELL: Descendant = {
  type: 'table-cell',
  children: [EMPTY_PARAGRAPH],
}

const VALIDATION_CELL_TEMPLATE: Descendant = {
  type: 'table-cell',
  children: [
    {
      type: 'paragraph',
      children: [
        {
          text: '✅ / ❌',
          fontSize: '24px',
        },
      ],
    },
  ],
}

const EXPERIENCE_TEMPLATE_ROW: Descendant = {
  type: 'table-row',
  children: [EMPTY_TABLE_CELL, EMPTY_TABLE_CELL, EMPTY_TABLE_CELL, EMPTY_TABLE_CELL, VALIDATION_CELL_TEMPLATE],
}

export const INITIAL_CONTENT_VALUE: Descendant[] = [EMPTY_PARAGRAPH]

export const EXPERIENCE_TEMPLATE_TABLE: Descendant[] = [
  EMPTY_PARAGRAPH,
  {
    type: 'table',
    children: [
      {
        type: 'table-head',
        children: [
          {
            type: 'table-row',
            children: [
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Expérience totale',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'table-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: '3 ans et 6 mois',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Expérience déclarée',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'table-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: '✅ / ❌',
                        fontSize: '24px',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    type: 'table',
    children: [
      {
        type: 'table-head',
        children: [
          {
            type: 'table-row',
            children: [
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Entreprise',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Poste',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Entrée / sortie',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Nbr années + mois',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'header-cell',
                children: [
                  {
                    type: 'paragraph',
                    children: [
                      {
                        text: 'Commentaire',
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'table-body',
        children: [
          {
            type: 'table-row',
            children: [
              {
                type: 'table-cell',
                children: [
                  // {
                  //   type: 'paragraph',
                  //   children: [
                  //     {
                  //       text: '9:00 AM',
                  //       bold: true,
                  //     },
                  //   ],
                  // },
                ],
              },
              {
                type: 'table-cell',
                children: [
                  // {
                  //   type: 'paragraph',
                  //   children: [
                  //     {
                  //       text: '9:00 AM',
                  //       bold: true,
                  //     },
                  //   ],
                  // },
                ],
              },
              {
                type: 'table-cell',
                children: [
                  // {
                  //   type: 'paragraph',
                  //   children: [
                  //     {
                  //       text: '9:00 AM',
                  //       bold: true,
                  //     },
                  //   ],
                  // },
                ],
              },
              {
                type: 'table-cell',
                children: [
                  // {
                  //   type: 'paragraph',
                  //   children: [
                  //     {
                  //       text: '9:00 AM',
                  //       bold: true,
                  //     },
                  //   ],
                  // },
                ],
              },
              {
                type: 'table-cell',
                children: [
                  // {
                  //   type: 'paragraph',
                  //   children: [
                  //     {
                  //       text: '9:00 AM',
                  //       bold: true,
                  //     },
                  //   ],
                  // },
                ],
              },
            ],
          },
          EXPERIENCE_TEMPLATE_ROW,
          EXPERIENCE_TEMPLATE_ROW,
          EXPERIENCE_TEMPLATE_ROW,
        ],
      },
    ],
  },
  EMPTY_PARAGRAPH,
]
