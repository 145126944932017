import { css } from '@emotion/react'
import { amountToString, multiplyByNumber, newAmount } from '@orus.eu/amount'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { ActivitySectionState } from '@orus.eu/dimensions'
import {
  FormRow,
  RowContainerV2,
  spacing,
  ValidatedTextFieldFormField,
  type ValidatedTypeMapper,
} from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { failure, success } from '@orus.eu/result'
import { memo, useState } from 'react'
import { amountCustomInputProps, amountMapper } from '../../../../../lib/amount'
import { validateInteger } from '../../../../../lib/validation'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DocumentGroupStatus } from '../DetailedSheetPage'
import CommonSection from './common/CommonSection'
import DocumentsList from './common/DocumentsList'

type ActivitySectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: ActivitySectionState
  stateBefore: ActivitySectionState
  setChanges: (changes: ActivitySectionState) => void
  subscriptionId: string
  idsFileGroupStatus?: DocumentGroupStatus[]
  onIdFileGroupStatusClick?: (category: GroupCategory) => void
}

export const ActivitySection = memo(function ActivitySection(props: ActivitySectionProps) {
  const { state, setChanges, onIdFileGroupStatusClick, idsFileGroupStatus } = props

  const { estimatedRevenue, workforce, activities, activitiesDistribution, detailedSheetActivitySynthesis } = state

  const [estimatedRevenueValue, setEstimatedRevenueValue] = useState(estimatedRevenue)
  const [workforceValue, setWorkforceValue] = useState(workforce)

  const handleClick = (category: GroupCategory, status: GroupStatus) => {
    if (status !== 'empty' && onIdFileGroupStatusClick) {
      onIdFileGroupStatusClick(category)
    }
  }

  const leftContent = (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing[40]};
          margin-bottom: ${spacing[50]};
        `}
      >
        {activities?.map(({ activity, displayName }) => {
          const distribution =
            activitiesDistribution?.type === 'multiple'
              ? activitiesDistribution!.distribution[activity as keyof typeof activitiesDistribution.distribution]
              : undefined

          return (
            <RowContainerV2 key={activity}>
              <Row
                variant="basic"
                css={css`
                  border: none;
                  padding: ${spacing[50]} ${spacing[50]};
                `}
                title={displayName}
                subtitle={
                  distribution
                    ? `${distribution.sharePercentage}% (${amountToString(
                        multiplyByNumber(estimatedRevenue ?? newAmount(0), distribution.sharePercentage / 100),
                        {
                          addCurrency: false,
                          displayDecimals: false,
                        },
                      )} €)`
                    : ''
                }
                titleVariant={'body2'}
              />
            </RowContainerV2>
          )
        })}
      </div>
      <FormRow>
        <ValidatedTextFieldFormField
          required
          size="small"
          label="Chiffre d’affaires HT"
          value={estimatedRevenue}
          placeholder="100 000 €"
          onBlur={() => {
            setChanges({ estimatedRevenue: estimatedRevenueValue })
          }}
          onChange={(value) => {
            setEstimatedRevenueValue(value)
          }}
          mapper={amountMapper}
          infoTooltip="Chiffre d’affaires approximatif que vous pensez faire en 1 an"
          InputProps={amountCustomInputProps}
        />
        <ValidatedTextFieldFormField
          size="small"
          label="Effectif"
          value={workforce}
          placeholder="1"
          onBlur={() => {
            setChanges({ workforce: workforceValue })
          }}
          onChange={(value) => {
            setWorkforceValue(value)
          }}
          mapper={integerMapper}
          infoTooltip="L’ensemble des salariés, les apprentis et le dirigeant. Ne sont pas compris les stagiaires, intérimaires et les conjoints non-employés"
          required
        />
      </FormRow>
    </>
  )

  const rightContent = <DocumentsList idsFileGroupStatus={idsFileGroupStatus} onIdFileGroupStatusClick={handleClick} />

  return (
    <CommonSection
      value={detailedSheetActivitySynthesis}
      title="Activités"
      leftContent={leftContent}
      rightContent={rightContent}
      defaultTable="activity-experience"
      onChange={(value) => {
        setChanges({ detailedSheetActivitySynthesis: value })
      }}
    />
  )
})

const integerMapper: ValidatedTypeMapper<number> = {
  inputType: 'number',
  format: (value) => value.toString(),
  formatPlaceholder: (value) => value.toString(),
  parse: (text, language) => {
    const result = validateInteger(text, language)
    return result.ok ? success(result.value) : failure(result.hint)
  },
}
