import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { OtherSectionState } from '@orus.eu/dimensions'
import { memo } from 'react'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DocumentGroupStatus } from '../DetailedSheetPage'
import CommonSection from './common/CommonSection'
import DocumentsList from './common/DocumentsList'

type OtherSectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: OtherSectionState
  stateBefore: OtherSectionState
  setChanges: (changes: OtherSectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (category: GroupCategory) => void
}

export const OtherSection = memo(function OtherSection(props: OtherSectionProps) {
  const { state, setChanges, onIdFileGroupStatusClick, idsFileGroupStatus } = props

  const { detailedSheetOtherSynthesis } = state

  const handleClick = (category: GroupCategory, status: GroupStatus) => {
    if (status !== 'empty') {
      onIdFileGroupStatusClick(category)
    }
  }

  const leftContent = <></>

  const rightContent = <DocumentsList idsFileGroupStatus={idsFileGroupStatus} onIdFileGroupStatusClick={handleClick} />

  return (
    <CommonSection
      value={detailedSheetOtherSynthesis}
      title="Autre"
      leftContent={leftContent}
      rightContent={rightContent}
      onChange={(value) => {
        setChanges({ detailedSheetOtherSynthesis: value })
      }}
    />
  )
})
