import { Editor } from 'slate'
import { type Format } from '../types'
import { isMarkActive } from './is-mark-active'

export function toggleMark(editor: Editor, format: Format): void {
  if (isMarkActive(editor, format)) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}
