import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { ExperienceSectionState } from '@orus.eu/dimensions'
import { DropdownFormField, FormRow } from '@orus.eu/pharaoh'
import { memo, useState } from 'react'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DocumentGroupStatus } from '../DetailedSheetPage'
import CommonSection from './common/CommonSection'
import DocumentsList from './common/DocumentsList'

type ExperienceSectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: ExperienceSectionState
  stateBefore: ExperienceSectionState
  setChanges: (changes: ExperienceSectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (category: GroupCategory) => void
}

export const ExperienceSection = memo(function ExperienceSection(props: ExperienceSectionProps) {
  const { state, setChanges, onIdFileGroupStatusClick, idsFileGroupStatus } = props

  const { rcdaBossExperience, detailedSheetExperienceSynthesis } = state

  const [rcdaBossExperienceValue, setRcdaBossExperienceValue] = useState(rcdaBossExperience ?? '')

  const handleClick = (category: GroupCategory, status: GroupStatus) => {
    if (status !== 'empty') {
      onIdFileGroupStatusClick(category)
    }
  }

  const leftContent = (
    <>
      <FormRow>
        <DropdownFormField
          required
          label="Expérience du dirigeant"
          infoTooltip="Les années complètes d’expérience, en incluant les apprentissages, les alternances (50 % en entreprise) et les stages de plus de 3 mois"
          value={rcdaBossExperienceValue}
          values={['0-1', '1-3', '4-5', '6-10', '10+']}
          onChange={(value) => {
            setRcdaBossExperienceValue(value as '0-1' | '1-3' | '4-5' | '6-10' | '10+')
            setChanges({ rcdaBossExperience: value as '0-1' | '1-3' | '4-5' | '6-10' | '10+' })
          }}
          size="small"
          labels={{
            '0-1': 'Moins d’1 an',
            '1-3': '1 à 3 ans',
            '4-5': '3 à 5 ans',
            '6-10': '5 à 10 ans',
            '10+': 'Plus de 10 ans',
          }}
        />
      </FormRow>
    </>
  )

  const rightContent = <DocumentsList idsFileGroupStatus={idsFileGroupStatus} onIdFileGroupStatusClick={handleClick} />

  return (
    <CommonSection
      value={detailedSheetExperienceSynthesis}
      title="Expérience"
      leftContent={leftContent}
      rightContent={rightContent}
      defaultTable="activity-experience"
      onChange={(value) => {
        setChanges({ detailedSheetExperienceSynthesis: value })
      }}
    />
  )
})
