import { colorTokens, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import type { RenderElementProps } from 'slate-react'

type TableHeaderProps = RenderElementProps

const TableHeader = memo(function TableHeader({ attributes, children }: TableHeaderProps) {
  return (
    <thead
      style={{
        textAlign: 'center',
        padding: `${spacing['20']}`,
        border: `1px solid ${colorTokens['color-stroke-base']}`,
        fontSize: '13px',
      }}
      {...attributes}
    >
      {children}
    </thead>
  )
})

export default TableHeader
