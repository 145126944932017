import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { InsuranceHistorySectionState } from '@orus.eu/dimensions'
import { DatepickerFormField, FormRow, TextFieldFormField, ValidatedTextFieldFormField } from '@orus.eu/pharaoh'
import { memo, useState } from 'react'
import { amountCustomInputProps, amountMapper } from '../../../../../lib/amount'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DocumentGroupStatus } from '../DetailedSheetPage'
import CommonSection from './common/CommonSection'
import DocumentsList from './common/DocumentsList'

type InsuranceHistorySectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: InsuranceHistorySectionState
  stateBefore: InsuranceHistorySectionState
  setChanges: (changes: InsuranceHistorySectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (label: GroupCategory) => void
}

export const InsuranceHistorySection = memo(function InsuranceHistorySection(props: InsuranceHistorySectionProps) {
  {
    const { state, setChanges, onIdFileGroupStatusClick, idsFileGroupStatus } = props

    const {
      rcdaClaimsCount,
      rcdaClaimsTotalValue,
      rcdaPreviousInsurersNames,
      rcdaInsurancePeriodStartDate,
      detailedSheetHistorySynthesis,
    } = state

    const [rcdaPreviousInsurersNamesValue, setRcdaPreviousInsurersNamesValue] = useState(
      rcdaPreviousInsurersNames ?? '',
    )
    const [rcdaInsurancePeriodStartDateValue, setRcdaInsurancePeriodStartDateValue] =
      useState(rcdaInsurancePeriodStartDate)
    const [rcdaClaimsCountValue, setRcdaClaimsCountValue] = useState(rcdaClaimsCount ?? 0)
    const [rcdaClaimsTotalValueValue, setRcdaClaimsTotalValueValue] = useState(rcdaClaimsTotalValue)

    const handleClick = (category: GroupCategory, status: GroupStatus) => {
      if (status !== 'empty') {
        onIdFileGroupStatusClick(category)
      }
    }

    const leftContent = (
      <>
        <FormRow>
          <TextFieldFormField
            required
            value={rcdaPreviousInsurersNamesValue}
            label="Assureur"
            size="small"
            placeholder="Axa, Generalli"
            onBlur={(value) => {
              setRcdaPreviousInsurersNamesValue(value.target.value)
              setChanges({ rcdaPreviousInsurersNames: value.target.value })
            }}
            onChange={(value) => {
              setRcdaPreviousInsurersNamesValue(value.target.value)
            }}
          />

          <DatepickerFormField
            label="Date de début"
            onChange={(value) => {
              setRcdaInsurancePeriodStartDateValue(value)
              setChanges({ rcdaInsurancePeriodStartDate: value })
            }}
            value={rcdaInsurancePeriodStartDateValue}
            size="small"
            fullWidth={true}
          />
        </FormRow>
        <FormRow>
          <TextFieldFormField
            required
            value={rcdaClaimsCountValue.toString()}
            label="Nombre de sinistres"
            size="small"
            placeholder="0"
            infoTooltip="Nombre de sinistres déclarés depuis que vous êtes assuré en RC décennale et/ou RC professionnelle."
            onBlur={() => {
              setChanges({ rcdaClaimsCount: rcdaClaimsCountValue })
            }}
            onChange={(value) => {
              setRcdaClaimsCountValue(Number(value.target.value))
            }}
          />
          <ValidatedTextFieldFormField
            size={'small'}
            label="Valeur des sinistres"
            value={rcdaClaimsTotalValue}
            infoTooltip="Valeur totale de l'ensemble des sinistres (frais et provisions inclus) qui ont fait l'objet d'une procédure d'indemnisation depuis votre premier contrat RC décennale et/ou RC professionnelle"
            onBlur={() => {
              setChanges({ rcdaClaimsTotalValue: rcdaClaimsTotalValueValue })
            }}
            onChange={(value) => {
              setRcdaClaimsTotalValueValue(value)
            }}
            placeholder="0 €"
            mapper={amountMapper}
            InputProps={amountCustomInputProps}
            required={true}
          />
        </FormRow>
      </>
    )

    const rightContent = (
      <DocumentsList idsFileGroupStatus={idsFileGroupStatus} onIdFileGroupStatusClick={handleClick} />
    )

    return (
      <CommonSection
        title="Historique d’assurance"
        leftContent={leftContent}
        rightContent={rightContent}
        value={detailedSheetHistorySynthesis}
        onChange={(value) => {
          setChanges({ detailedSheetHistorySynthesis: value })
        }}
      />
    )
  }
})
