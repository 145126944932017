import { css } from '@emotion/react'
import { spacing, Text } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'

import type { DetailedSheetSectionType } from '../../DetailedSheetPage'
import RichTextEditor from '../rich-text-editor'

type CommentAreaProps = {
  displayLabel?: boolean
  size?: 'small' | 'large'
  placeholder?: string
  onChange: (value: string) => void
  value: string | null | undefined
  defaultTable?: DetailedSheetSectionType
}

export const CommentArea = memo(function CommentArea({
  displayLabel = true,
  onChange,
  value,
}: CommentAreaProps): ReactNode {
  return (
    <div>
      {displayLabel ? (
        <Text
          variant="body2Medium"
          css={css`
            margin-bottom: ${spacing[20]};
          `}
        >
          Commentaire
        </Text>
      ) : null}
      <RichTextEditor onChange={onChange} value={value} />
    </div>
  )
})
