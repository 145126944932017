import { colorTokens, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { type RenderElementProps, useSlateSelection, useSlateStatic } from 'slate-react'
import { TableCursor } from 'slate-table'
import type { Th } from '../../types'

type TableHeaderCellProps = RenderElementProps & {
  element: Th
}

const TableHeaderCell = memo(function TableHeaderCell({ attributes, children, element }: TableHeaderCellProps) {
  if (element.type !== 'header-cell') {
    throw new Error('Element "Th" must be of type "header-cell"')
  }

  useSlateSelection()
  const editor = useSlateStatic()
  const selected = TableCursor.isSelected(editor, element)

  return (
    <th
      style={{
        fontWeight: 500,
        backgroundColor: `${selected ? '#ccc' : colorTokens['color-bg-base-tertiary']}`,
        border: `0.5px solid ${colorTokens['color-stroke-base']}`,
        padding: `${spacing['20']}`,
        textAlign: 'center',
      }}
      rowSpan={element.rowSpan}
      colSpan={element.colSpan}
      {...attributes}
    >
      {children}
    </th>
  )
})

export default TableHeaderCell
