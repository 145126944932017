import { Badge, Button, Divider, LayoutTabbed, spacing, Tab, useDialogVisibility } from '@orus.eu/pharaoh'
import { ButtonGroup } from '@orus.eu/pharaoh/src/components/button/button-group'

import { memo, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react'

import styled from '@emotion/styled'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { assert } from '@orus.eu/error'
import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { useBlocker, useParams } from '@tanstack/react-router'
import { trpcReact } from '../../../../client'
import { getCollectedFileDownloadLink } from '../../../../lib/download-links'
import { useNavigateBack } from '../../../../lib/hooks/use-navigate-back'
import { getHubspotContactLink } from '../../../../lib/hubspot-util'
import { usePermissions } from '../../../../lib/use-permissions'
import { openLinkInNewWindow } from '../../../../lib/window-util'
import { GlobalLoadingState } from '../../../molecules/global-loading-state'
import { UnsavedChangesDialog } from '../../../organisms/unsaved-changes-dialog'
import { LABEL_PER_DOCUMENT_CATEGORY } from '../../subscription-v2/elements/documents-list-element/labels'
import type { File } from '../../subscription-v2/elements/documents-list-element/types'
import { useQuoteEditorState } from '../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import { FileViewerDialog, type ViewerFile } from '../common/FileViewerDialog'
import {
  detailedSheetCompleteStatusBadgeVariantColor,
  detailedSheetCompleteStatusText,
} from '../utils/detailed-sheet-common'
import { ActivitySection } from './components/ActivitySection'
import { CompanySection } from './components/CompanySection'
import { ExperienceSection } from './components/ExperienceSection'
import { InsuranceHistorySection } from './components/InsuranceHistorySection'
import { OtherSection } from './components/OtherSection'
import { RepresentativeSection } from './components/RepresentativeSection'
import { SummarySection } from './components/SummarySection'

export type DocumentGroupStatus = {
  label: string
  category: GroupCategory
  status: GroupStatus
}

export type DetailedSheetSectionType =
  | 'reprensentative'
  | 'company'
  | 'activity-experience'
  | 'insurance-history'
  | 'other'

const DetailedSheetPage = memo(function DetailedSheetPage(): ReactNode {
  const { subscriptionId } = useParams({ strict: false })
  assert(subscriptionId, 'missing subscriptionId url param')

  const navigateBack = useNavigateBack()

  const { changes, setChanges, before, after, commit, reset } = useQuoteEditorState({ subscriptionId })

  const viewerDialog = useDialogVisibility('detailed-sheet-viewer')
  const [selectedFileId, setSelectedFileId] = useState<string | undefined>(undefined)
  const [selectedCategory, setSelectedCategory] = useState<GroupCategory | 'none' | undefined>(undefined)

  const collectedFiles = trpcReact.collectedFile.getAllFiles.useQuery({ subscriptionId })

  const filesPerCategory = Object.values(collectedFiles.data?.files ?? {}).reduce<
    Partial<Record<GroupCategory | 'none', File[]>>
  >((acc, file) => {
    const groupCategory = (file.groupId ? collectedFiles.data?.groups[file.groupId]?.category : 'none') ?? 'none'
    acc[groupCategory] = (acc[groupCategory] ?? []).concat(file)
    return acc
  }, {})

  const allFilesSortedByCategory = useMemo(() => {
    const fileIndexPerCategory: Map<GroupCategory | 'none', number> = new Map()
    return Object.values(collectedFiles.data?.files ?? {})
      .flatMap<ViewerFile>((file) => {
        if (file.deleted) return []
        const group = file.groupId ? collectedFiles.data?.groups[file.groupId] : undefined
        const fileIndexForCategory = fileIndexPerCategory.get(group?.category ?? 'none') ?? 1
        fileIndexPerCategory.set(group?.category ?? 'none', fileIndexForCategory + 1)

        return [
          {
            uri: getCollectedFileDownloadLink(file.id),
            fileType: file.mimeType,
            metadata: {
              file,
              fileIndexForCategory,
              group: group ?? null,
            },
          },
        ]
      })
      .sort((a, b) => {
        const aCategory = a.metadata.group?.category ?? 'none'
        const bCategory = b.metadata.group?.category ?? 'none'
        if (aCategory !== bCategory) {
          return aCategory < bCategory ? -1 : 1
        }
        return a.metadata.fileIndexForCategory - b.metadata.fileIndexForCategory
      })
  }, [collectedFiles.data?.files, collectedFiles.data?.groups])

  const selectedFile = useMemo(() => {
    return allFilesSortedByCategory.find((file) => file.metadata.file.id === selectedFileId)
  }, [allFilesSortedByCategory, selectedFileId])

  const statusPerCategory = useMemo(
    () =>
      Object.values(collectedFiles.data?.groups ?? {}).reduce<Partial<Record<GroupCategory | 'none', GroupStatus>>>(
        (acc, group) => {
          const groupCategory = group.category ?? 'none'
          acc[groupCategory] = group.status
          return acc
        },
        {},
      ),
    [collectedFiles],
  )

  useEffect(() => {
    const indexOfSelectedCategory = allFilesSortedByCategory.findIndex(
      (file) => file.metadata.group?.category === selectedCategory,
    )
    setSelectedFileId(allFilesSortedByCategory[indexOfSelectedCategory]?.metadata.file.id)
  }, [allFilesSortedByCategory, selectedCategory])

  const handleCloseFileViewerDialog = () => {
    navigateBack()
    viewerDialog.hide()
  }

  const handleSelectedCategoryChange = (category: GroupCategory) => {
    setSelectedCategory(category)
    const indexOfSelectedCategory = allFilesSortedByCategory.findIndex(
      (file) => file.metadata.group?.category === category,
    )
    setSelectedFileId(allFilesSortedByCategory[indexOfSelectedCategory]?.metadata.file.id)
    viewerDialog.show()
  }

  const state = Object.assign({}, after, changes)

  const currentEmail = changes.email || before?.email || undefined

  const tabs = useMemo(() => {
    return ['Récapitulatif', 'Représentant', 'Entreprise', 'Expérience', 'Activités', 'Historique d’assurance', 'Autre']
  }, [])

  const {
    show: showUnsavedChangesDialog,
    hide: hideUnsavedChangesDialog,
    visible: isUnsavedChangesDialogVisible,
  } = useDialogVisibility('detailed-sheet-unsaved-changes')

  const hasUnsavedChanges = useMemo(() => Object.keys(changes).length > 0, [changes])

  const {
    proceed: blockerProceed,
    reset: blockerReset,
    status: blockerStatus,
  } = useBlocker({
    condition: hasUnsavedChanges,
  })

  useEffect(() => {
    if (blockerStatus === 'blocked' && !isUnsavedChangesDialogVisible && hasUnsavedChanges) {
      showUnsavedChangesDialog()
    }
  }, [blockerStatus, isUnsavedChangesDialogVisible, showUnsavedChangesDialog, hasUnsavedChanges])

  const proceedUnsavedChanges = useCallback(() => {
    blockerProceed()
    hideUnsavedChangesDialog()
    reset()
    navigateBack()
  }, [blockerProceed, hideUnsavedChangesDialog, reset, navigateBack])

  const resetUnsavedChanges = useCallback(() => {
    blockerReset()
    hideUnsavedChangesDialog()
  }, [blockerReset, hideUnsavedChangesDialog])

  const userType = usePermissions().type

  const isPlatform = userType === 'platform'

  const openHubspotContact = useCallback(() => {
    if (!currentEmail) {
      alert(
        "Il n'y a pas d'email associé à ce devis. Cette fonctionnalité n'est " +
          "disponible que lorsqu'un email a été saisi",
      )
      return
    }
    const url = getHubspotContactLink(currentEmail)
    openLinkInNewWindow(url)
  }, [currentEmail])

  const hasChanges = Object.keys(changes).length > 0

  const handleBack = () => {
    if (hasChanges) {
      showUnsavedChangesDialog()
    } else {
      hideUnsavedChangesDialog()
      navigateBack()
    }
  }

  if (!before) return <GlobalLoadingState />
  return (
    <>
      {isUnsavedChangesDialogVisible ? (
        <UnsavedChangesDialog reset={resetUnsavedChanges} proceed={proceedUnsavedChanges} />
      ) : null}

      {selectedFile && viewerDialog.visible ? (
        <FileViewerDialog
          selectedFile={selectedFile}
          onSelectedFileChange={(file) => setSelectedFileId(file.metadata.file.id)}
          allFiles={allFilesSortedByCategory}
          totalForCategory={filesPerCategory[selectedFile.metadata.group?.category ?? 'none']?.length ?? 0}
          allowedCategories={selectedFile.metadata.group?.category ? [selectedFile.metadata.group.category] : ['other']}
          close={handleCloseFileViewerDialog}
          onReviewFinished={null}
          invalidate={() => {
            void collectedFiles.refetch()
          }}
        />
      ) : null}

      <LayoutTabbed
        header={
          <Header
            title={'Fiche détaillée'}
            badge={
              <Badge
                variant={detailedSheetCompleteStatusBadgeVariantColor[before?.detailedSheetCompleteStatus ?? 'pending']}
              >
                {detailedSheetCompleteStatusText[before?.detailedSheetCompleteStatus ?? 'pending']}
              </Badge>
            }
            leftButton={<Button icon="arrow-left-regular" size="small" variant="secondary" onClick={handleBack} />}
            rightButtonGroups={[
              <ButtonGroup key="detailed-sheet-buttons">
                <Button
                  disabled
                  key="download-button"
                  variant="secondary"
                  size="small"
                  icon="arrow-down-to-line-regular"
                  ariaLabel={'Menu de la page de souscription'}
                />
                {isPlatform ? (
                  <Button
                    key="hubspot-button"
                    title="Hubspot"
                    variant="secondary"
                    size="small"
                    onClick={openHubspotContact}
                    icon="hubspot-brands"
                  />
                ) : (
                  <></>
                )}
                <Button key="save-button" variant="primary" size="small" disabled={!hasChanges} onClick={commit}>
                  Sauvegarder
                </Button>
              </ButtonGroup>,
            ]}
          />
        }
        sidePanel={<></>}
        isSidePanelOpen={false}
        tabs={tabs.map((tab, tabIndex) => {
          return {
            tab: (
              <Tab key={tabIndex} variant={'neutral'}>
                {tab}
              </Tab>
            ),
            content: (
              <ContentContainer>
                <SummarySection
                  changes={changes}
                  stateAfter={after}
                  state={state}
                  stateBefore={before}
                  setChanges={setChanges}
                  subscriptionId={subscriptionId}
                  quote={before.quote}
                />
                <RepresentativeSection
                  changes={changes}
                  stateAfter={after}
                  state={state}
                  stateBefore={before}
                  setChanges={setChanges}
                  subscriptionId={subscriptionId}
                  idsFileGroupStatus={[
                    {
                      label: LABEL_PER_DOCUMENT_CATEGORY['id'],
                      category: 'id' as GroupCategory,
                      status: statusPerCategory.id ?? 'empty',
                    },
                  ]}
                  onIdFileGroupStatusClick={handleSelectedCategoryChange}
                />
                <Divider orientation="horizontal" />
                <CompanySection
                  changes={changes}
                  stateAfter={after}
                  state={state}
                  stateBefore={before}
                  setChanges={setChanges}
                  subscriptionId={subscriptionId}
                  idsFileGroupStatus={[
                    {
                      label: LABEL_PER_DOCUMENT_CATEGORY['immatriculation'],
                      category: 'immatriculation' as GroupCategory,
                      status: statusPerCategory.immatriculation ?? 'empty',
                    },
                    {
                      label: LABEL_PER_DOCUMENT_CATEGORY['proformaInvoice'],
                      category: 'proformaInvoice' as GroupCategory,
                      status: statusPerCategory.proformaInvoice ?? 'empty',
                    },
                    {
                      label: LABEL_PER_DOCUMENT_CATEGORY['RIB'],
                      category: 'RIB' as GroupCategory,
                      status: statusPerCategory.RIB ?? 'empty',
                    },
                  ]}
                  onIdFileGroupStatusClick={handleSelectedCategoryChange}
                />
                <Divider orientation="horizontal" />
                <ExperienceSection
                  changes={changes}
                  stateAfter={after}
                  state={state}
                  stateBefore={before}
                  setChanges={setChanges}
                  subscriptionId={subscriptionId}
                  idsFileGroupStatus={[
                    {
                      label: LABEL_PER_DOCUMENT_CATEGORY['experience'],
                      category: 'experience' as GroupCategory,
                      status: statusPerCategory.experience ?? 'empty',
                    },
                  ]}
                  onIdFileGroupStatusClick={handleSelectedCategoryChange}
                />
                <Divider orientation="horizontal" />
                <ActivitySection
                  changes={changes}
                  stateAfter={after}
                  state={state}
                  stateBefore={before}
                  setChanges={setChanges}
                  subscriptionId={subscriptionId}
                  idsFileGroupStatus={[]}
                  onIdFileGroupStatusClick={handleSelectedCategoryChange}
                />
                <Divider orientation="horizontal" />
                <InsuranceHistorySection
                  changes={changes}
                  stateAfter={after}
                  state={state}
                  stateBefore={before}
                  setChanges={setChanges}
                  subscriptionId={subscriptionId}
                  idsFileGroupStatus={[
                    {
                      label: LABEL_PER_DOCUMENT_CATEGORY['infoReport'],
                      category: 'infoReport' as GroupCategory,
                      status: statusPerCategory.infoReport ?? 'empty',
                    },
                    {
                      label: LABEL_PER_DOCUMENT_CATEGORY['decennaleCertificate'],
                      category: 'decennaleCertificate' as GroupCategory,
                      status: statusPerCategory.decennaleCertificate ?? 'empty',
                    },
                  ]}
                  onIdFileGroupStatusClick={handleSelectedCategoryChange}
                />
                <Divider orientation="horizontal" />
                <OtherSection
                  changes={changes}
                  stateAfter={after}
                  state={state}
                  stateBefore={before}
                  setChanges={setChanges}
                  subscriptionId={subscriptionId}
                  idsFileGroupStatus={[
                    {
                      label: LABEL_PER_DOCUMENT_CATEGORY['other'],
                      category: 'other' as GroupCategory,
                      status: statusPerCategory.other ?? 'empty',
                    },
                  ]}
                  onIdFileGroupStatusClick={handleSelectedCategoryChange}
                />
              </ContentContainer>
            ),
          }
        })}
      />
    </>
  )
})

export default DetailedSheetPage

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[70]};
  max-width: 1600px;
`
