import { colorTokens } from '@orus.eu/pharaoh'
import { memo } from 'react'
import type { RenderElementProps } from 'slate-react'

type TableBodyProps = RenderElementProps

const TableBody = memo(function TableBody({ attributes, children }: TableBodyProps) {
  return (
    <tbody
      style={{
        borderBottom: `1px solid ${colorTokens['color-stroke-base']}`,
        fontSize: '13px',
      }}
      {...attributes}
    >
      {children}
    </tbody>
  )
})

export default TableBody
