import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { CustomIconButton } from '../button/CustomIconButton'

type DropdownListItemProps = {
  value: string
  disabled?: boolean
  onClick: (value: string) => void
  icon?: React.ReactNode
  label?: string
}

const DropdownListItem = memo(function DropdownListItem({
  disabled,
  onClick,
  icon,
  label,
  value,
}: DropdownListItemProps) {
  return (
    <CustomIconButton
      active={!disabled}
      disabled={disabled}
      onMouseDown={(event) => {
        onClick(value)
        event.preventDefault()
      }}
      css={css`
        display: flex;
        gap: ${spacing['40']};
      `}
    >
      {icon}
      <DropdownListItemLabel>{label}</DropdownListItemLabel>
    </CustomIconButton>
  )
})

export default DropdownListItem

const DropdownListItemLabel = styled.div`
  display: flex;
  font-size: 14px;
  white-space: nowrap;
`
