import type { ReactEditor } from 'slate-react'
import { withTable } from 'slate-table'

export function withTables(editor: ReactEditor): ReactEditor {
  return withTable(editor, {
    blocks: {
      table: 'table',
      thead: 'table-head',
      tbody: 'table-body',
      tfoot: 'table-footer',
      tr: 'table-row',
      th: 'header-cell',
      td: 'table-cell',
      content: 'paragraph',
    },
    withDelete: true,
    withFragments: true,
    withInsertText: true,
    withNormalization: true,
    withSelection: true,
    withSelectionAdjustment: true,
  })
}
