'use client'

import { css } from '@emotion/react'
import { spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { useSlate } from 'slate-react'
import { isMarkActive, toggleMark } from '../../utils'
import { CustomIconButton } from '../button/CustomIconButton'
import { Bold, Italic, Strikethrough, Underline } from '../icons'

export const FormatButtons = memo(function FormatButtons() {
  const editor = useSlate()

  return (
    <div
      css={css`
        display: flex;
        gap: ${spacing['10']};
      `}
    >
      <CustomIconButton
        active={isMarkActive(editor, 'bold')}
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, 'bold')
        }}
      >
        <Bold height={20} width={20} />
      </CustomIconButton>
      <CustomIconButton
        active={isMarkActive(editor, 'italic')}
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, 'italic')
        }}
      >
        <Italic height={20} width={20} />
      </CustomIconButton>
      <CustomIconButton
        active={isMarkActive(editor, 'underline')}
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, 'underline')
        }}
      >
        <Underline height={20} width={20} />
      </CustomIconButton>
      <CustomIconButton
        active={isMarkActive(editor, 'strikethrough')}
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, 'strikethrough')
        }}
      >
        <Strikethrough height={20} width={20} />
      </CustomIconButton>
    </div>
  )
})
