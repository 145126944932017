import { memo } from 'react'
import type { RenderElementProps } from 'slate-react'

type TableRowProps = RenderElementProps

const TableRow = memo(function TableRow({ attributes, children }: TableRowProps) {
  return <tr {...attributes}>{children}</tr>
})

export default TableRow
