import { colorTokens, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { type RenderElementProps, useSlateSelection, useSlateStatic } from 'slate-react'
import { TableCursor } from 'slate-table'
import type { Td } from '../../types'

type TableCellProps = RenderElementProps & { element: Td }

const TableCell = memo(function TableCell({ attributes, children, element }: TableCellProps) {
  if (element.type !== 'table-cell') {
    throw new Error('Element "Td" must be of type "table-cell"')
  }

  useSlateSelection()
  const editor = useSlateStatic()
  const selected = TableCursor.isSelected(editor, element)

  return (
    <td
      style={{
        backgroundColor: `${selected ? '#ccc' : 'white'}`,
        border: `0.5px solid ${colorTokens['color-stroke-base']}`,
        padding: `${spacing['20']}`,
        textAlign: 'center',
      }}
      rowSpan={element.rowSpan}
      colSpan={element.colSpan}
      {...attributes}
    >
      {children}
    </td>
  )
})

export default TableCell
