import { useReducer, useRef, type Dispatch, type MutableRefObject, type SetStateAction } from 'react'

export type PopupRef = MutableRefObject<HTMLElement | null>

type State = { showPopup: boolean }
type Action = { type: 'toggle' | 'close' }

function popupReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'toggle':
      return { showPopup: !state.showPopup }
    case 'close':
      return { showPopup: false }
    default:
      return state
  }
}

function usePopup(popupRef: PopupRef): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [{ showPopup }, dispatch] = useReducer(popupReducer, { showPopup: false })
  const isMounted = useRef(false)

  if (!isMounted.current) {
    document.addEventListener('click', (e: MouseEvent) => {
      const clickedComponent = e.target as Node
      if (popupRef.current && !popupRef.current.contains(clickedComponent)) {
        dispatch({ type: 'close' })
      }
    })
    isMounted.current = true
  }

  const togglePopup = () => dispatch({ type: 'toggle' })

  return [showPopup, togglePopup]
}

export default usePopup
