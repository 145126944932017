import { Transforms } from 'slate'
import type { ReactEditor } from 'slate-react'
import type { Image } from '../../types'

const insertImage = (editor: ReactEditor, url: string) => {
  const imageNode: Image = {
    type: 'image',
    url,
    children: [{ text: '' }],
  }
  Transforms.insertNodes(editor, imageNode)
}

export function withImages(editor: ReactEditor): ReactEditor {
  const { insertData } = editor

  editor.insertData = (data: DataTransfer) => {
    const files = data.files
    if (files && files.length > 0) {
      const file = files[0]
      if (file.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onload = () => {
          const url = reader.result as string
          insertImage(editor, url)
        }
        reader.readAsDataURL(file)
        return
      }
    }
    insertData(data)
  }

  return editor
}
