import styled from '@emotion/styled'
import { spacing, Text } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { FormFieldsContainer } from '../../../../../templates/form-fields-container'
import type { DetailedSheetSectionType } from '../../DetailedSheetPage'
import { CommentArea } from './CommentArea'

type CommonSectionProps = {
  title: string
  leftContent: ReactNode
  rightContent?: ReactNode
  onChange: (value: string) => void
  value: string | null | undefined
  defaultTable?: DetailedSheetSectionType
}

const CommonSection = memo(function CommonSection({
  title,
  leftContent,
  rightContent,
  onChange,
  value,
  defaultTable,
}: CommonSectionProps): ReactNode {
  return (
    <CommonSectionContainer>
      <Text variant="body1Medium">{title}</Text>
      <FormFieldsContainer>
        <CommonSectionFormContainer>
          <CommonSectionFormLeftContainer>
            {leftContent}
            <CommentArea onChange={onChange} value={value} defaultTable={defaultTable} />
          </CommonSectionFormLeftContainer>
          <CommonSectionFormRightContainer>
            {rightContent ? (
              <>
                <Text variant="body2Medium">Pièces</Text>
                {rightContent}
              </>
            ) : null}
          </CommonSectionFormRightContainer>
        </CommonSectionFormContainer>
      </FormFieldsContainer>
    </CommonSectionContainer>
  )
})

const CommonSectionContainer = styled.div`
  display: grid;
  flex-direction: column;
  gap: ${spacing[60]};
`
const CommonSectionFormContainer = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 325px;
  gap: ${spacing[90]};
`
const CommonSectionFormLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`
const CommonSectionFormRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[40]};
`

export default CommonSection
