'use client'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { colorTokens, Divider, spacing } from '@orus.eu/pharaoh'
import { memo, useCallback } from 'react'
import { Transforms, type BaseEditor } from 'slate'
import { HistoryEditor } from 'slate-history'
import type { ReactEditor } from 'slate-react'
import { TableEditor } from 'slate-table'
import ColorPicker from '../../color-picker/ColorPicker'
import { EXPERIENCE_TEMPLATE_TABLE } from '../../constants'
import EmojiPickerButton from '../../emoji-picker/EmojiPicker'
import { CustomIconButton } from '../button/CustomIconButton'
import {
  ColumnInsertLeft,
  ColumnInsertRight,
  ColumnRemove,
  MergeCell,
  Redo,
  RowInsertBottom,
  RowInsertTop,
  RowRemove,
  SplitCell,
  Table,
  TableMinus,
  TablePlus,
  Undo,
} from '../icons'
import { DropdownButton } from './Dropdown'
import { FormatButtons } from './FormatButtons'

type Props = {
  editor: BaseEditor & ReactEditor & HistoryEditor
  canUndo: boolean
  canRedo: boolean
  canDelete: boolean
  canSplit: boolean
  canMerge: boolean
  canManipulateTable: boolean
}

const TABLE_LIST_ITEMS = [
  {
    value: 'new-table',
    icon: <TablePlus width={20} height={20} />,
    label: 'Insérer un tableau',
  },
  {
    value: 'merge-cells',
    icon: <MergeCell width={20} height={20} />,
    label: 'Fusionner les cellules',
  },
  {
    value: 'split-cells',
    icon: <SplitCell width={20} height={20} />,
    label: 'Séparer les cellules',
  },
  {
    value: 'delete-table',
    icon: <TableMinus width={20} height={20} />,
    label: 'Supprimer le tableau',
  },
  {
    value: 'insert-row-above',
    icon: <RowInsertTop width={20} height={20} />,
    label: 'Insérer une ligne au dessus',
  },
  {
    value: 'insert-row-below',
    icon: <RowInsertBottom width={20} height={20} />,
    label: 'Insérer une ligne en dessous',
  },
  {
    value: 'delete-row',
    icon: <RowRemove width={20} height={20} />,
    label: 'Supprimer la ligne',
  },
  {
    value: 'insert-column-left',
    icon: <ColumnInsertLeft width={20} height={20} />,
    label: 'Insérer une colonne à gauche',
  },
  {
    value: 'insert-column-right',
    icon: <ColumnInsertRight width={20} height={20} />,
    label: 'Insérer une colonne à droite',
  },
  {
    value: 'delete-column',
    icon: <ColumnRemove width={20} height={20} />,
    label: 'Supprimer la colonne',
  },
  {
    value: 'insert-template',
    icon: <TablePlus width={20} height={20} />,
    label: 'Insérer un template',
  },
]

export const Toolbar = memo(function Toolbar({
  editor,
  canUndo,
  canRedo,
  canDelete,
  canSplit,
  canMerge,
  canManipulateTable,
}: Props) {
  const handleOnTableAction = useCallback(
    (value: string) => {
      switch (value) {
        case 'new-table':
          TableEditor.insertTable(editor, { rows: 3, cols: 3 })
          break
        case 'merge-cells':
          TableEditor.merge(editor)
          break
        case 'split-cells':
          TableEditor.split(editor)
          break
        case 'delete-table':
          TableEditor.removeTable(editor)
          break
        case 'insert-row-above':
          TableEditor.insertRow(editor, { before: true })
          break
        case 'insert-row-below':
          TableEditor.insertRow(editor)
          break
        case 'delete-row':
          TableEditor.removeRow(editor)
          break
        case 'insert-column-left':
          TableEditor.insertColumn(editor, { before: true })
          break
        case 'insert-column-right':
          TableEditor.insertColumn(editor)
          break
        case 'insert-template':
          Transforms.insertNodes(editor, EXPERIENCE_TEMPLATE_TABLE)
          break
        default:
          break
      }
    },
    [editor],
  )

  const getAvailableTableOptions = useCallback(() => {
    const availableOptions = TABLE_LIST_ITEMS.map((item) => {
      if (item.value === 'new-table' && !canManipulateTable) {
        return item.value
      }
      if (item.value === 'insert-template' && !canManipulateTable) {
        return item.value
      }
      if (item.value === 'merge-cells' && !canMerge) {
        return undefined
      }
      if (item.value === 'split-cells' && !canSplit) {
        return undefined
      }
      if (item.value === 'delete-table' && !canDelete) {
        return undefined
      }
      if (!canManipulateTable) {
        return undefined
      }
      return item.value
    })

    return availableOptions.filter((option) => option !== undefined)
  }, [canDelete, canMerge, canSplit, canManipulateTable])

  return (
    <ToolbarContainer>
      <>
        <div
          css={css`
            display: flex;
            gap: ${spacing['50']};
          `}
        >
          <FormatButtons />
          <Divider orientation="vertical" />
          <div
            css={css`
              display: flex;
              gap: ${spacing['30']};
            `}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <ColorPicker format={'color'} editor={editor} />
            </div>
            <div
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <ColorPicker format={'backgroundColor'} editor={editor} />
            </div>
          </div>
          <Divider orientation="vertical" />
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <EmojiPickerButton editor={editor} format={'emoji'} />
          </div>
          <Divider orientation="vertical" />
          <DropdownButton
            buttonContent={<Table width={20} height={20} />}
            options={TABLE_LIST_ITEMS}
            availableOptions={getAvailableTableOptions()}
            onClick={handleOnTableAction}
          />
        </div>
      </>
      <div
        css={css`
          display: flex;
          gap: ${spacing['10']};
        `}
        role="group"
      >
        <CustomIconButton
          title="Undo"
          active={canUndo}
          onMouseDown={(event) => {
            event.preventDefault()
            HistoryEditor.undo(editor)
          }}
        >
          <Undo />
        </CustomIconButton>
        <CustomIconButton
          title="Redo"
          active={canRedo}
          onMouseDown={(event) => {
            event.preventDefault()
            HistoryEditor.redo(editor)
          }}
        >
          <Redo />
        </CustomIconButton>
      </div>
    </ToolbarContainer>
  )
})

const ToolbarContainer = styled.div`
  box-shadow: 0 0 0 1px ${colorTokens['color-stroke-base']};
  border-radius: ${spacing['30']} ${spacing['30']} 0 0;
  padding: ${spacing['20']};
  gap: ${spacing['20']};
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
`
