import { colorTokens, spacing } from '@orus.eu/pharaoh'
import { memo } from 'react'
import type { RenderElementProps } from 'slate-react'

const Table = memo(function Table({ attributes, children }: RenderElementProps) {
  return (
    <table
      style={{
        border: `${spacing['5']} solid ${colorTokens['color-stroke-base']}`,
        borderRadius: `${spacing['30']}`,
        borderCollapse: 'collapse',
        borderSpacing: '0',
        overflow: 'hidden',
        width: '100%',
      }}
      {...attributes}
    >
      {children}
    </table>
  )
})

export default Table
