import { css } from '@emotion/react'
import { amountToString, type Amount } from '@orus.eu/amount'
import { type AggregatedQuote, type SummarySectionState } from '@orus.eu/dimensions'
import {
  Badge,
  colorTokens,
  FormRow,
  ResponsiveCard,
  RowContainerV2,
  Select,
  spacing,
  Text,
  TextFieldFormField,
} from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { memo, useState } from 'react'
import { FormFieldsContainer } from '../../../../templates/form-fields-container'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DetailedSheetCompleteStatus } from '../../utils/detailed-sheet-common'
import { CommentArea } from './common/CommentArea'

type DetailedSheetStatus = 'other' | 'pioupiou' | 'poussin' | 'coq' | 'aigle'

type DetailedSheetStatusEntry = {
  label: string
  description: string
  emoji: string
}

const detailedSheetStatusEntries: Record<DetailedSheetStatus, DetailedSheetStatusEntry> = {
  other: {
    label: 'Autre',
    description: 'Autre',
    emoji: '🦉',
  },
  pioupiou: {
    label: 'Piou-Piou',
    description: 'Création de -6 mois SANS antécédent d‘assurance',
    emoji: '🐣',
  },
  poussin: {
    label: 'Poussin',
    description: 'Création de +6 mois SANS antécédent d‘assurance',
    emoji: '🐥',
  },
  coq: {
    label: 'Coq',
    description: 'Création de -3 ans AVEC antécédent d‘assurance',
    emoji: '🐓',
  },
  aigle: {
    label: 'Aigle',
    description: 'Création de +3 ans AVEC antécédent d‘assurance',
    emoji: '🦅',
  },
}

const detailedSheetStatusLabels = Object.fromEntries(
  Object.entries(detailedSheetStatusEntries).map(([key, entry]) => [key, entry.label]),
)

const detailedSheetStatusValues = Object.keys(detailedSheetStatusEntries)

type DetailedSheetCompleteStatusEntry = {
  label: string
}

const detailedSheetCompleteStatusEntries: Record<DetailedSheetCompleteStatus, DetailedSheetCompleteStatusEntry> = {
  pending: {
    label: 'En cours',
  },
  accepted: {
    label: 'Acceptée',
  },
  rejected: {
    label: 'Refusée',
  },
}

const detailedSheetCompleteStatusLabels = Object.fromEntries(
  Object.entries(detailedSheetCompleteStatusEntries).map(([key, entry]) => [key, entry.label]),
)

const detailedSheetCompleteStatusValues = Object.keys(detailedSheetCompleteStatusEntries)

type SummarySectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: SummarySectionState
  stateBefore: SummarySectionState
  setChanges: (changes: SummarySectionState) => void
  subscriptionId: string
  quote: AggregatedQuote | undefined | null
}

export const SummarySection = memo(function SummarySection(props: SummarySectionProps) {
  const { state, setChanges, quote } = props

  const {
    companyName,
    activity,
    detailedSheetCompleteStatus,
    detailedSheetExperienceStatus,
    detailedSheetFirstLevelResponsible,
    detailedSheetSecondLevelResponsible,
    detailedSheetThirdLevelResponsible,
    detailedSheetGlobalSynthesis,
  } = state

  const [responsibleLevel1, setResponsibleLevel1] = useState(detailedSheetFirstLevelResponsible ?? '')
  const [responsibleLevel2, setResponsibleLevel2] = useState(detailedSheetSecondLevelResponsible ?? '')
  const [responsibleLevel3, setResponsibleLevel3] = useState(detailedSheetThirdLevelResponsible ?? '')

  return (
    <ResponsiveCard
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[60]};
        border: 1px solid ${colorTokens['color-stroke-base']};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          css={css`
            display: grid;
            align-items: center;
            flex-direction: column;
            gap: ${spacing[20]};
          `}
        >
          <Text variant="subtitle1">{companyName}</Text>
          <Text variant="body2">{activity?.displayName}</Text>
        </div>
        {quote ? (
          <div
            css={css`
              display: grid;
              align-items: end;
              flex-direction: column;
              gap: ${spacing[20]};
            `}
          >
            <MonthlyPriceBlock price={quote.subsequentMonthsTotalPremium} />
            <YearlyPriceBlock price={quote.yearlyTotalPremium} />
          </div>
        ) : null}
      </div>
      <RowContainerV2
        css={css`
          padding: ${spacing[40]};
        `}
      >
        <FormFieldsContainer>
          <FormRow>
            <Select
              css={css`
                align-self: center;
                width: min-content;
                max-width: 280px;
              `}
              aria-label="Statut de la fiche détaillée"
              value={detailedSheetExperienceStatus ?? 'pioupiou'}
              values={detailedSheetStatusValues}
              onChange={(value) => {
                setChanges({
                  detailedSheetExperienceStatus: value as DetailedSheetStatus,
                })
              }}
              size="small"
              placeholder="Statut"
              labels={detailedSheetStatusLabels}
            />
            <Row
              variant="basic"
              css={css`
                white-space: nowrap;
                padding: ${spacing[30]} ${spacing[50]};
                background-color: ${colorTokens['color-bg-base-hover']};
                border-radius: ${spacing[30]};
              `}
              leftAvatar={<div>{detailedSheetStatusEntries[detailedSheetExperienceStatus ?? 'pioupiou'].emoji}</div>}
              title={detailedSheetStatusEntries[detailedSheetExperienceStatus ?? 'pioupiou'].description}
              titleVariant="body2"
            />
            <Select
              css={css`
                max-width: 280px;
                align-self: center;
              `}
              aria-label="Statut de la fiche détaillée"
              value={detailedSheetCompleteStatus?.toString() ?? 'pending'}
              fullWidth={false}
              values={detailedSheetCompleteStatusValues}
              onChange={(value) => {
                setChanges({
                  detailedSheetCompleteStatus: value as DetailedSheetCompleteStatus,
                })
              }}
              size="small"
              placeholder="Statut"
              labels={detailedSheetCompleteStatusLabels}
            />
          </FormRow>
        </FormFieldsContainer>
      </RowContainerV2>
      <FormFieldsContainer>
        <div
          css={css`
            display: grid;
            flex-direction: row;
            grid-template-columns: 1fr 260px;
            gap: ${spacing[70]};
          `}
        >
          <CommentArea
            displayLabel={false}
            size="large"
            placeholder="Votre synthèse sur le dossier"
            value={detailedSheetGlobalSynthesis}
            onChange={(value) => {
              setChanges({ detailedSheetGlobalSynthesis: value })
            }}
          />
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: ${spacing[40]};
            `}
          >
            <TextFieldFormField
              label="Responsable Niveau 1"
              value={responsibleLevel1}
              size="small"
              placeholder="responsable@orus.eu"
              infoTooltip="Le responsable commercial gère le dossier de souscription et de la collecte des justificatifs"
              onBlur={(value) => {
                setChanges({
                  detailedSheetFirstLevelResponsible: value.target.value,
                })
              }}
              onChange={(value) => {
                setResponsibleLevel1(value.target.value)
              }}
            />
            <TextFieldFormField
              label="Responsable Niveau 2"
              value={responsibleLevel2}
              size="small"
              placeholder="responsable@orus.eu"
              infoTooltip="L’analyste assurance vérifie le dossier et les justificatifs pour s’assurer de leur conformité"
              onBlur={(value) => {
                setChanges({
                  detailedSheetSecondLevelResponsible: value.target.value,
                })
              }}
              onChange={(value) => {
                setResponsibleLevel2(value.target.value)
              }}
            />
            <TextFieldFormField
              label="Responsable Niveau 3"
              value={responsibleLevel3}
              size="small"
              placeholder="responsable@orus.eu"
              infoTooltip="Le superviseur assurance valide l'ensemble du dossier pour garantir sa complétude"
              onBlur={(value) => {
                setChanges({
                  detailedSheetThirdLevelResponsible: value.target.value,
                })
              }}
              onChange={(value) => {
                setResponsibleLevel3(value.target.value)
              }}
            />
          </div>
        </div>
      </FormFieldsContainer>
    </ResponsiveCard>
  )
})

type PriceBlockProps = {
  price: Amount
}

const MonthlyPriceBlock = memo(function MonthlyPriceBlock({ price }: PriceBlockProps) {
  return (
    <div
      css={css`
        display: flex;
        column-gap: ${spacing[30]};
      `}
    >
      <Text variant="subtitle1">{amountToString(price, { addCurrency: true })}</Text>
      <Badge>Mois</Badge>
    </div>
  )
})

const YearlyPriceBlock = memo(function YearlyPriceBlock({ price }: PriceBlockProps) {
  return (
    <Text
      css={css`
        text-align: end;
      `}
      variant="body2"
    >{`${amountToString(price, { addCurrency: true })} / an`}</Text>
  )
})
