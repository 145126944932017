import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import { Chip } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { LABEL_AND_VARIANT_PER_GROUP_STATUS } from '../../../../subscription-v2/elements/documents-list-element/chip-config'
import type { DocumentGroupStatus } from '../../DetailedSheetPage'
import DocumentButton from './DocumentButton'

type DocumentsListProps = {
  idsFileGroupStatus?: DocumentGroupStatus[]
  onIdFileGroupStatusClick?: (category: GroupCategory, status: GroupStatus) => void
}

const DocumentsList = memo(function DocumentsList(props: DocumentsListProps) {
  const { idsFileGroupStatus, onIdFileGroupStatusClick } = props

  const hasDocuments = idsFileGroupStatus && idsFileGroupStatus.length > 0

  return hasDocuments
    ? idsFileGroupStatus.map(({ label, category, status }) => (
        <DocumentButton
          key={label}
          title={label}
          disabled={status === 'empty'}
          avatar={
            <Chip
              variant={LABEL_AND_VARIANT_PER_GROUP_STATUS[status].variant}
              dark={LABEL_AND_VARIANT_PER_GROUP_STATUS[status].dark}
              size="large"
            >
              {LABEL_AND_VARIANT_PER_GROUP_STATUS[status].label}
            </Chip>
          }
          onClick={() => onIdFileGroupStatusClick?.(category, status)}
        />
      ))
    : null
})

export default DocumentsList
