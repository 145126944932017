import { css } from '@emotion/react'
import type { GroupCategory } from '@orus.eu/backend/src/events/collected-file/collected-file-event'
import type { GroupStatus } from '@orus.eu/backend/src/services/collected-documents/collected-file-service'
import type { RepresentativeSectionState } from '@orus.eu/dimensions'
import { FormRow, spacing, Text, TextFieldFormField } from '@orus.eu/pharaoh'
import { memo, useState, type ReactNode } from 'react'
import { openLinkInNewWindow } from '../../../../../lib/window-util'
import type { useQuoteEditorState } from '../../common/backoffice-quote-editor-v2/quote-editor-v2-state'
import type { DocumentGroupStatus } from '../DetailedSheetPage'
import CommonSection from './common/CommonSection'
import DocumentButton from './common/DocumentButton'
import DocumentsList from './common/DocumentsList'

type RepresentativeSectionProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: RepresentativeSectionState
  stateBefore: RepresentativeSectionState
  setChanges: (changes: RepresentativeSectionState) => void
  subscriptionId: string
  idsFileGroupStatus: DocumentGroupStatus[]
  onIdFileGroupStatusClick: (label: GroupCategory) => void
}

export const RepresentativeSection = memo(function RepresentativeSection(props: RepresentativeSectionProps): ReactNode {
  {
    const { state, setChanges, onIdFileGroupStatusClick, idsFileGroupStatus } = props

    const { firstName, lastName, detailedSheetCustomerSynthesis } = state

    const [firstNameValue, setFirstNameValue] = useState(firstName ?? '')
    const [lastNameValue, setLastNameValue] = useState(lastName ?? '')

    const handleClick = (category: GroupCategory, status: GroupStatus) => {
      if (status !== 'empty') {
        onIdFileGroupStatusClick(category)
      }
    }

    const leftContent = (
      <FormRow>
        <TextFieldFormField
          required
          label="Prénom"
          size="small"
          placeholder="Jean"
          value={firstNameValue}
          onBlur={(value) => setChanges({ firstName: value.target.value })}
          onChange={(value) => setFirstNameValue(value.target.value)}
        />
        <TextFieldFormField
          required
          label="Nom"
          size="small"
          placeholder="Dupond"
          value={lastNameValue}
          onBlur={(value) => setChanges({ lastName: value.target.value })}
          onChange={(value) => setLastNameValue(value.target.value)}
        />
      </FormRow>
    )

    const rightContent = (
      <>
        <DocumentsList idsFileGroupStatus={idsFileGroupStatus} onIdFileGroupStatusClick={handleClick} />
        <Text
          css={css`
            margin-top: ${spacing[50]};
          `}
          variant="body2Medium"
        >
          Liens
        </Text>
        <DocumentButton
          title={'ComplyAdvantage'}
          rightIcon="arrow-up-right-regular"
          onClick={() => {
            openLinkInNewWindow('https://eu.mesh.complyadvantage.com/exports?page_size=25&page_number=1')
          }}
        />
      </>
    )

    return (
      <CommonSection
        title="Représentant"
        leftContent={leftContent}
        rightContent={rightContent}
        defaultTable="reprensentative"
        value={detailedSheetCustomerSynthesis}
        onChange={(value) => {
          setChanges({ detailedSheetCustomerSynthesis: value })
        }}
      />
    )
  }
})
