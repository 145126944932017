import { css } from '@emotion/react'

import { colorTokens, spacing } from '@orus.eu/pharaoh'
import isHotkey from 'is-hotkey'
import { memo, useCallback, useMemo, useState, type CSSProperties } from 'react'
import { createEditor, type Descendant } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, Slate, withReact, type RenderElementProps, type RenderLeafProps } from 'slate-react'
import { TableCursor, TableEditor } from 'slate-table'
import { Table, TableBody, TableCell, TableFooter, TableHeader, TableHeaderCell, TableRow } from './components/table'

import CustomizableLeaf from './components/CustomizableLeaf'
import { Toolbar } from './components/toolbar/Toolbar'
import { INITIAL_CONTENT_VALUE } from './constants/initialValue'
import { withImages } from './plugins/image/withImages'
import { withTables } from './plugins/table/withTables'
import type { CustomElement, Td, Th } from './types'
import { toggleMark } from './utils'

type RichTextEditorProps = {
  onChange: (value: string) => void
  value: string | null | undefined
}

type ElementProps = RenderElementProps & { element: CustomElement }

const customStyle: CSSProperties = {
  width: '100%',
  padding: `${spacing['50']}`,
  borderRadius: `0 0 ${spacing['30']} ${spacing['30']}`,
  border: 'none',
  minHeight: '150px',
  overflow: 'hidden',
  fontSize: '13px',
  boxShadow: `0 0 0 1px ${colorTokens['color-stroke-base']}`,
  background: `${colorTokens['color-bg-base-normal']}`,
}

const RichTextEditor = memo(function RichTextEditor({ onChange, value }: RichTextEditorProps) {
  const [canMerge, setCanMerge] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [canDelete, setCanDelete] = useState(false)
  const [canSplit, setCanSplit] = useState(false)
  const [canManipulateTable, setCanManipulateTable] = useState(false)
  const [content, setContent] = useState<Descendant[]>(value ? JSON.parse(value) : INITIAL_CONTENT_VALUE)

  const editor = useMemo(() => withHistory(withTables(withImages(withReact(createEditor())))), [])

  const renderElement = useCallback((props: ElementProps) => {
    switch (props.element.type) {
      case 'table':
        return <Table {...props} />
      case 'table-head':
        return <TableHeader {...props} />
      case 'table-body':
        return <TableBody {...props} />
      case 'table-footer':
        return <TableFooter {...props} />
      case 'table-row':
        return <TableRow {...props} />
      case 'header-cell':
        return (
          <TableHeaderCell element={props.element as Th} attributes={props.attributes}>
            {props.children}
          </TableHeaderCell>
        )
      case 'table-cell':
        return (
          <TableCell element={props.element as Td} attributes={props.attributes}>
            {props.children}
          </TableCell>
        )
      case 'paragraph':
      default:
        return <p {...props.attributes}>{props.children}</p>
    }
  }, [])

  const renderLeaf = useCallback((props: RenderLeafProps) => <CustomizableLeaf {...props} />, [])

  const HOTKEYS = useMemo(
    () => ({
      // Formatting
      BOLD: isHotkey('mod+b'),
      ITALIC: isHotkey('mod+i'),
      UNDERLINE: isHotkey('mod+u'),
      STRIKETHROUGH: isHotkey('mod+s'),

      // Navigation
      ARROW_UP: isHotkey('up'),
      ARROW_DOWN: isHotkey('down'),
      ARROW_LEFT: isHotkey('left'),
      ARROW_RIGHT: isHotkey('right'),
      TAB: isHotkey('tab'),
      SHIFT_TAB: isHotkey('shift+tab'),
    }),
    [],
  )

  const convertContentToString = useCallback((content: Descendant[] | undefined) => {
    return JSON.stringify(content)
  }, [])

  const handleChange = useCallback(
    (value: Descendant[]) => {
      setCanUndo(editor.history.undos.length > 0)
      setCanRedo(editor.history.redos.length > 0)
      setCanDelete(TableCursor.isInTable(editor))
      setCanSplit(TableCursor.isInTable(editor))
      setCanManipulateTable(TableCursor.isInTable(editor))

      setContent(value)
    },
    [editor],
  )

  const handleBlur = useCallback(() => {
    const contentString = convertContentToString(content)
    onChange(contentString)
  }, [content, convertContentToString, onChange])

  return (
    <Slate
      editor={editor}
      initialValue={content}
      onSelectionChange={() => setCanMerge(TableEditor.canMerge(editor))}
      onChange={handleChange}
      css={css`
        border: ${spacing['5']} solid ${colorTokens['color-stroke-base']};
        min-height: ${spacing['100']};
      `}
    >
      <Toolbar
        editor={editor}
        canMerge={canMerge}
        canUndo={canUndo}
        canRedo={canRedo}
        canDelete={canDelete}
        canSplit={canSplit}
        canManipulateTable={canManipulateTable}
      />
      <Editable
        placeholder="Votre commentaire..."
        style={customStyle}
        onBlur={handleBlur}
        onDragStart={() => {
          if (TableCursor.isInTable(editor)) {
            return true
          }
          return false
        }}
        onKeyDown={(event) => {
          if (TableCursor.isInTable(editor)) {
            if (HOTKEYS.ARROW_DOWN(event) && TableCursor.isOnEdge(editor, 'bottom')) {
              event.preventDefault()
              return TableCursor.downward(editor)
            }
            if (HOTKEYS.ARROW_UP(event) && TableCursor.isOnEdge(editor, 'top')) {
              event.preventDefault()
              return TableCursor.upward(editor)
            }
            if (HOTKEYS.ARROW_RIGHT(event) && TableCursor.isOnEdge(editor, 'end')) {
              event.preventDefault()
              return TableCursor.forward(editor)
            }
            if (HOTKEYS.ARROW_LEFT(event) && TableCursor.isOnEdge(editor, 'start')) {
              event.preventDefault()
              return TableCursor.backward(editor)
            }
            if (HOTKEYS.TAB(event)) {
              if (TableCursor.isInLastCell(editor)) {
                TableEditor.insertRow(editor)
              }
              event.preventDefault()
              return TableCursor.forward(editor, { mode: 'all' })
            }
            if (HOTKEYS.SHIFT_TAB(event)) {
              event.preventDefault()
              return TableCursor.backward(editor, { mode: 'all' })
            }
          }

          if (HOTKEYS.BOLD(event)) {
            event.preventDefault()
            return toggleMark(editor, 'bold')
          }
          if (HOTKEYS.ITALIC(event)) {
            event.preventDefault()
            return toggleMark(editor, 'italic')
          }
          if (HOTKEYS.UNDERLINE(event)) {
            event.preventDefault()
            return toggleMark(editor, 'underline')
          }
          if (HOTKEYS.STRIKETHROUGH(event)) {
            event.preventDefault()
            return toggleMark(editor, 'bold')
          }
        }}
        renderPlaceholder={({ attributes, children }) => (
          <div
            {...attributes}
            css={css`
              margin-top: ${spacing['50']};
              font-size: 13px;
            `}
          >
            {children}
          </div>
        )}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        spellCheck={false}
      />
    </Slate>
  )
})

export default RichTextEditor
