import { css } from '@emotion/react'
import { RowContainerV2, spacing, type CompoundIconName } from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { memo, type ReactNode } from 'react'

type DocumentButtonProps = {
  title: string
  avatar?: ReactNode
  rightIcon?: CompoundIconName
  disabled?: boolean
  onClick: () => void
}

const DocumentButton = memo(function DocumentButton({
  title,
  avatar,
  rightIcon,
  onClick,
  disabled,
}: DocumentButtonProps): ReactNode {
  return (
    <RowContainerV2>
      <Row
        variant="basic"
        css={css`
          padding: ${avatar ? spacing[30] : spacing[40]} ${spacing[50]};
        `}
        title={title}
        titleVariant={'body2'}
        rightAvatar={avatar}
        rightIcon={rightIcon}
        onClick={onClick}
        disabled={disabled ?? false}
      />
    </RowContainerV2>
  )
})

export default DocumentButton
